import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import arrowWhiteLeft from "../../images/arrow-white-left.svg"
import arrowWhiteRight from "../../images/arrow-white-right.svg"

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6875rem 1.125rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  background: rgba(0, 0, 0, 0.6);
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.125rem;
  font: 600 0.875rem/1rem ${props => props.theme.secondaryFont};
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
  }

  &.next {
    text-align: right;
  }
`

const ArrowImg = styled.img`
  width: auto;
  height: 1.25rem;

  ${props => props.left ?
    "margin-right: 0.625rem;"
  :
    "margin-left: 0.625rem;"
  }
`

const BlogNav = ({ prevPost, nextPost }) => {
  return (
    <Wrapper>
      {prevPost ?
        <StyledLink to={prevPost.link} className="prev">
          <ArrowImg src={arrowWhiteLeft} role="none" left />
          {prevPost.title}
        </StyledLink>
      :
        <span />
      }
      {nextPost ?
        <StyledLink to={nextPost.link} className="next">
          {nextPost.title}
          <ArrowImg src={arrowWhiteRight} role="none" />
        </StyledLink>
      :
        <span />
      }
    </Wrapper>
  )
}

export default BlogNav
