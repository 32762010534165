import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BlogNav from "../components/blog/BlogNav"
import BlogPost from "../components/blog/BlogPost"
import BlogFooter from "../components/blog/BlogFooter"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  padding: 0 0.5rem 3.25rem;
  background: ${props => props.theme.roomColors[7].base};
`

const Bg = styled.div`
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 9rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
`

const BackLinkWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin: 2.625rem 0 0.625rem;
  text-align: center;
`

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #eee;
  text-decoration: none;
  text-shadow: 0 0 3px black;

  &:hover {
    text-decoration: underline;
    color: #eee;
  }
`

export default function BlogPostTemplate({
  data: { klaraAndOssiYaml: { title, profile_pic, header_pic }, otherBlog },
  pageContext: { lang, pagePath, startPageLink, post, prevPost, nextPost, author },
}) {
  const intl = useIntl()
  const firstImage = post.images && post.images.length && post.images[0]
  const firstImageFileType = firstImage ?
    (firstImage.image.toLowerCase().endsWith(".jpg") ? "jpeg" : firstImage.image.split(".").slice(-1)[0]).toLowerCase() :
    null
  const headerPicFileType = (header_pic.toLowerCase().endsWith(".jpg") ? "jpeg" : header_pic.split(".").slice(-1)[0]).toLowerCase()

  const nav = (
    <BlogNav
      prevPost={prevPost}
      nextPost={nextPost}
    />
  )

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={`${post.title} – ${title}`}
        image={{
          url: firstImage ? firstImage.image : `${header_pic}?nf_resize=fit&w=200`,
          alt: firstImage ? firstImage.altText : `${intl.formatMessage({ id: "portraitOf" })} ${author}`,
          type: `image/${firstImageFileType || headerPicFileType}`,
          ...(firstImage ? {} : { width: 200, height: 200 }),
        }}
      />
      <Header
        lang={lang}
        title={title}
        room={7}
        headingLevel="h2"
      />
      <Bg style={{ backgroundImage: `url(${header_pic})` }} />
      <BackLinkWrapper>
        <StyledLink to={startPageLink}>
          {intl.formatMessage({ id: "backToOverview" })}
        </StyledLink>
      </BackLinkWrapper>
      <BlogPost
        lang={lang}
        author={author}
        post={post}
        profilePic={profile_pic}
        nav={nav}
      />
      <BlogFooter
        lang={lang}
        title={otherBlog.title}
        path={otherBlog.path}
        profilePic={otherBlog.header_pic}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $author: String, $otherBlog: String) {
    klaraAndOssiYaml(lang: { eq: $lang }, author: { eq: $author }) {
      title
      profile_pic
      header_pic
    }
    otherBlog: klaraAndOssiYaml(lang: { eq: $lang }, author: { eq: $otherBlog }) {
      title
      path
      header_pic
    }
  }
`
