import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Wrapper = styled.article`
  position: relative;
  max-width: 40rem;
  margin: 0 auto;
  padding-bottom: 0.625rem;
  border-radius: 0.3125rem;
  background: white;

  &::after {
    content: "";
    position: absolute;
    top: 3.125rem;
    right: 0.5rem;
    width: 9rem;
    height: 4.5rem;
    background: url(${props => props.backgroundImage}) no-repeat center;
    background-size: contain;
    opacity: 0.2;
  }
`

const Title = styled.h1`
  position: relative;
  z-index: 3;
  margin: 1.5rem 1.125rem;
  font: bold 1.75rem/1.1 ${props => props.theme.secondaryFont};
  color: #333;
`

const Text = styled.div`
  margin: 0 0 1rem;
  padding: 0 1.125rem;
  font-size: 1rem;
  line-height: 1.5;
  color: black;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${props => props.multiImg && `
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 1.25rem;

      figure {
        width: calc(50% - 1rem);
      }
    }
  `}
`

const Figure = styled.figure`
  display: inline-block;
  width: 100%;
  margin: 0.5rem 0 0.5rem;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @media (min-width: 600px) {
    width: 100%;
  }
`

const Caption = styled.figcaption`
  margin-top: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #707070;
  font-style: italic;
  text-align: left;
  background: #F5F5F5;
  padding: 0.5rem 1rem;
`

const BlogPost = ({ lang, author, nav, post, profilePic }) => {
  return (
    <Wrapper backgroundImage={profilePic}>
      {nav}
      <Title>{post.title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: post.text }} />
      {post.images && post.images.length > 0 &&
        <ImageWrapper multiImg={(post.images || []).length > 1}>
          {post.images.map((image) => (
            <Figure key={image.id}>
              <Image src={image.image} alt={image.altText} />
              {image.caption &&
                <Caption>{image.caption}</Caption>
              }
            </Figure>
          ))}
        </ImageWrapper>
      }
    </Wrapper>
  )
}

export default BlogPost
